import api from './api';

class RoleService {

  getItems(cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/roles${cadenaBusqueda}`);
  }

  getItem(id) {
    return api.get(`/api/roles/${id}`);
  }

  newItem(params) {
    return api.post(`/api/roles/`, params)
  }

  editItem(params) {
    return api.put(`/api/roles/${params.id}`, params)
  }
}

export default new RoleService();
