<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title>Edición de Item {{item.nombre}} de la lista {{listaSelect.nombre}}</v-card-title>
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="item.valor_texto"
                label="Nombre del Item"
                :rules="rules.concat(v => (v && v.length <= 100) || 'Máximo 100 caracteres')"
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="item.descripcion"
                label="Descripción"
                :rules="rules.concat(v => (v && v.length <= 250) || 'Máximo 250 caracteres')"
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="item.abreviatura"
                label="Abreviatura"
                :rules="required.concat(v => (v && v.length <= 20) || 'Máximo 20 caracteres')"
                :counter="20"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="item.orden"
                label="Orden"
                :counter="3"
                hide-details="auto"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="item.valor_numerico"
                label="Valor Numerico"
                :counter="20"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-switch
                v-model="item.valor_booleano"
                :label="`Valor Booleano`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-spacer></v-spacer>
              <v-switch
                v-model="item.editable"
                :label="`Editable`"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="6">
              <v-spacer></v-spacer>
              <v-switch
                v-model="item.activo"
                :label="`Estado`"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ListaService from "@/services/lista.service";
import RoleService from "@/services/role.service";
import validationField from "@/util/validationField";
import { mapState } from "vuex";

export default {
  name: "Calidad",

  data() {
    return {
      item: {
        nombre: undefined,
        descripcion: undefined,
        orden: '0',
        valor_numerico: '0',
        editable: true,
        activo: true,
      },
      listaSelect: {
        id: undefined,
        nombre: undefined
      },
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [
        (value) => !!value || "Requerido"
      ],
      loading: false,
      loadingSave: false,
      message: "",
      dialog: false
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Gestión de Usuarios"))
      this.$router.push("/dashboard")

    this.listaSelect = JSON.parse(localStorage.getItem("lista_select"))
    if (localStorage.getItem("datos_lista")) {
      this.item = JSON.parse(localStorage.getItem("datos_lista"))
    }
  },
  methods: {
    save() {
      this.loadingSave = true;
      console.log(this.item);
      if (this.item.hasOwnProperty("id")) {
        ListaService.editItem(this.listaSelect.id, this.item).then(
          (response) => {
            this.$router.push({ path: `/listas` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        ListaService.newItem(this.listaSelect.id, this.item).then(
          (response) => {
            this.$router.push({ path: `/listas` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    preCancelar() {
      this.dialog = true
    },
    goBack() {
      this.$router.push({ path: "/listas" });
    },
  },
};
</script>

<style>
</style>
